import { Space } from 'antd';
import OrderConcreteLoadsTable from './components/OrderConcreteLoadsTable';
import UpdateConcreteLoadOrderId from './components/UpdateConcreteLoadOrderId';
import UnloadedToSigned from './components/UnloadedToSigned';

const CoreManagement = (): JSX.Element => {

    return (

        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <OrderConcreteLoadsTable />
            <UpdateConcreteLoadOrderId />
            <UnloadedToSigned />
        </Space>
    );
};

export default CoreManagement;


