import { Button, Card, Col, Divider, Form, Input, Row } from 'antd';
import { useContext } from 'react';
import ErrorNotification from 'src/components/Notification/ErrorNotification';
import SuccessNotification from 'src/components/Notification/SuccessNotification';
import { ToolAdminContext } from 'src/providers/AdminToolProvider';

export default function UpdateUnloadedToSigned() {

    const { operationalToolClient } = useContext(ToolAdminContext);

    const [form] = Form.useForm();


    async function updateUnloadedToSigned() {

        try {
            const {
                concreteLoadId
            } = await form.validateFields();

            operationalToolClient.updateUnloadedToSigned(concreteLoadId)
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message: 'ConcreteLoad status updated',
                        description: ''
                    });

                } else { 
                  ErrorNotification({
                    message: 'Error updating concreteLoad status',
                    description: ''
                  })
                }
              })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
        }
        
        catch (e: any) {
            ErrorNotification({
                message: 'Form not filled correctly',
                description: 'Please, make sure that all the fields are filled correctly'
            });
        }

    }

    return (
            <Card title='Unloaded to Signed'>

                <Form form={form}>

                    <Row gutter={[16, 16]}>

                        <Col>
                            <Form.Item name={'concreteLoadId'} 
                                label="Concrete Load Id"
                                rules={[
                                    {
                                        required: true,
                                        pattern: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
                                        message: (
                                            <span data-testid="errorConcreteLoadId">
                                                Please insert a valid value
                                            </span>
                                        )
                                    }
                                ]}>

                                <Input
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                />
                            </Form.Item>
                        </Col>
                       
                    </Row>


                    <Divider />

                    <Row>

                        <Button type='primary' onClick={() => updateUnloadedToSigned()}>
                            Update concrete load
                        </Button>

                    </Row>
                </Form>
            </Card>
            
    );
};

